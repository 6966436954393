import {concat, Observable, of, throwError} from 'rxjs';
import {APP_BASE_HREF, PlatformLocation} from '@angular/common';
import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {delay, retryWhen, switchMap, take} from 'rxjs/operators';
import {BrowserService} from '../services/browser.service';
import {Router} from '@angular/router';
import {environment} from '../../environments/environment';
import {AuthService} from '../services/auth.service';
import {Util} from '../helpers/util';

@Injectable()
export class SessionInterceptor implements HttpInterceptor {

  // tslint:disable-next-line:max-line-length
  constructor(public browserService: BrowserService, private router: Router, private authService: AuthService, private platformLocation: PlatformLocation) {
  }

  // Se interceptan todas las peticiones http para permitir el envío de sesiones
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (request.url.indexOf('api.ipify.org') > -1 || request.url.indexOf('searchvoc.php') > -1) {
      request = request.clone({
      });
    } else {
      request = request.clone({
        withCredentials: true
      });
    }
    return next.handle(request).pipe(
      retryWhen(error => concat(error.pipe(
          switchMap(( httpError: any) => {
            if (httpError instanceof HttpErrorResponse) {
              if (httpError.status === 400 || httpError.status === 422 || httpError.status === 404 || httpError.status === 403) {
                // La petición no es válida, no tiene sentido repetirla
                return throwError(httpError);
              } else if (httpError.status === 401 && (! httpError.url.endsWith('/datosMatriculaBDISimple'))) {
                if (httpError.url.endsWith('/auth') || httpError.url.endsWith('/authSAML')) {
                  // No tiene permisos para acceder
                  if (this.authService.getState() === AuthService.AUTHENTICATED) {
                    this.authService.logoutSAML();

                    const returnUrl = this.platformLocation.protocol + '//'
                        + this.platformLocation.hostname + (this.platformLocation.port ? ':' + this.platformLocation.port : '')
                        + this.platformLocation.pathname.replace(/(autenticacion)|(cerrar-sesion)/, 'auth?logout=true');
                    const logoutUrl = environment.samlUrl + 'logout?returnTo='
                        + encodeURIComponent(returnUrl);

                    window.location.href = logoutUrl;
                  }

                  return throwError(httpError);
                } else if (this.authService.getState() === AuthService.AUTHENTICATED) {
                  console.error('interceptor no ( auth o authSAML ) y autenticado si 401 ', httpError);
                  this.authService.logoutSAML();

                  let returnUrl = this.platformLocation.protocol + '//'
                      + this.platformLocation.hostname + (this.platformLocation.port ? ':' + this.platformLocation.port : '')
                      + this.platformLocation.pathname;
                  const partes = returnUrl.split('/');
                  partes.pop(); // Elimina el último fragmento
                  returnUrl = partes.join('/') + '/auth?logout=true';
                  const logoutUrl = environment.samlUrl + 'logout?returnTo=' + encodeURIComponent(returnUrl);
//                  console.log("cerrar-sesion 4 script no auth remota  redirigiendo a "+ logoutUrl);
                  window.location.href = logoutUrl;
                  // return throwError(httpError);
                } else {
                  //if (! httpError.url.endsWith('/datosMatriculaBDISimple')) {
                  //  this.router.navigate(['cerrar-sesion']);
                  // console.error("interceptor", environment.portalUrl + this.router.url);
                  // TODO: hack porque el middlelwareSSO de la API devuelve 401 en localhost..
                  this.router.navigateByUrl('autenticacion' + '?redirectPage=' +
                     encodeURIComponent(encodeURIComponent(environment.portalUrl + Util.reemplazarPrimerCaracter(this.router.url, '/'))));
                  return throwError(httpError);
                }
              }
            }
            return of(httpError);
          }),
          take(3),
          delay(5000)
        ), throwError({error: 'La petición ha fallado tras 3 reintentos', errNo: '500'})))
    );
  }

}
