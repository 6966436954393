import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import {IonicSafeString, ToastController} from '@ionic/angular';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Aviso} from '../classes/aviso';
import {ApiResult} from '../classes/api.result';


/**
 * Application service.
 */
@Injectable({
  providedIn: 'root'
})
export class UpdateService {

  constructor(private swUpdate: SwUpdate, public toastController: ToastController, private httpClient: HttpClient) {
    this.swUpdate.versionUpdates.subscribe(event => {
      if (event.type === 'VERSION_DETECTED') {
        this.presentToastUpdate('Descargando nueva versión...');
      } else if (event.type === 'VERSION_READY') {
        const buttons = [
          {
            text: 'Cancelar',
            role: 'cancel'
          },
          {
            text: 'Actualizar',
            role: 'info',
            handler: () => {
              document.location.reload();
            }
          }
        ];
        this.presentToastUpdate('Nueva versión disponible', 86400000, buttons);
      } else if (event.type === 'VERSION_INSTALLATION_FAILED') {
        this.presentToastUpdate('Error al instalar la nueva versión');
      } else {
        // console.log('UpdateService: No new version detected');
      }
    });
  }

  async presentToastUpdate(message: string = '', duration: number = 10000, buttons = []) {
    const toast = await this.toastController.create({
      message: new IonicSafeString(message),
      duration,
      buttons
    });

    await toast.present();
  }

  public getAvisos(idTipoAviso: number): Observable<Aviso[]> {
    const body = {};
    return this.httpClient.get<ApiResult>(environment.apiUrl + '/nsr/avisos', body)
        .pipe(
            map(res => {
              // console.log('GET AVISOS: ' + JSON.stringify(res.result));
              const avisos: Aviso[] = [];
              if (res && res.result && res.result.length > 0){
                  res.result.forEach( (elem) => {
                    avisos.push(Aviso.parse(elem));
                  });
              }
              // console.log('array', avisos);
              return avisos;
            })
        );
  }
}
