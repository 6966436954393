

import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {User} from '../classes/user';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {catchError, map, tap, timeout} from 'rxjs/operators';
import {UserService} from './user.service';
import {StorageService} from './storage.service';
import {ApiResult} from '../classes/api.result';
import {AuthResult} from '../classes/auth.result';

@Injectable()
export class AuthService {
  public static AUTHENTICATED = 1;
  public static NO_AUTHENTICATED = 2;
  // Estado del usuario para saber si está autenticado o no
  public state: BehaviorSubject<number>;
  public lastLoginError ;

  constructor(public httpClient: HttpClient, private storage: StorageService, private userService: UserService) {
    this.state = new BehaviorSubject<number>(AuthService.NO_AUTHENTICATED);
    this.storage.get('user')
      .then((res) => {
        if (res) {
          this.userService.setUser(new User(res.nsrId, res.email, res.email, res.name, res.lastName, Object.values(res.roles)));
          this.setState(AuthService.AUTHENTICATED);
        } else {
          this.doLogin();
        }
      });
  }

  public get authenticated(): boolean {
    return this.getState() === AuthService.AUTHENTICATED;
  }

  public auth(email: string = null, password: string = null): Observable<User> {
    let body = {};
    if (email !== null && password !== null) {
      body = { email, password };
    }

    return this.httpClient.post<User>(environment.apiUrl + '/authAVIP', body)
      .pipe(
        map( res => new User(res.nsrId, res.email, res.email, res.name, res.lastName, Object.values(res.roles))),
        tap(user => {
          this.userService.setUser(user);
          this.setState(AuthService.AUTHENTICATED);
          // this.lastLoginError = '';
          // console.log('usuario autenticado: ' + JSON.stringify(user));
        })
        , catchError( error => {
            this.lastLoginError = error.error.result; // JSON.stringify(error);
            return throwError(error);
        })
      );
  }

  public logout(): Observable<User> {
    const body = {};

    return this.httpClient.post<User>(environment.apiUrl + '/auth/logout', body)
      .pipe(
        tap(user => {
          this.userService.setUser(null);
          this.setToken(null).then(() => {
            this.setState(AuthService.NO_AUTHENTICATED);
          });
          // console.log('usuario desautenticado: ' + JSON.stringify(user));
        }),
        catchError( error => {
          this.userService.setUser(null);
          this.setToken(null).then(() => {
            this.setState(AuthService.NO_AUTHENTICATED);
          });
          return throwError(error);
        })
      );
  }

  public getState(): number {
    return this.state.getValue();
  }

  public getStateObservable(): BehaviorSubject<number> {
    return this.state;
  }

  public setToken(token: string): Promise<any> {
    return this.storage.set('token', token);
  }

  public getToken(): Promise<string> {
    return this.storage.get('token');
  }

  public loginSAML() {
    return this.httpClient
      .get(environment.apiUrl + '/authSAML')
      .pipe(timeout(10000), catchError(this.validateError));
    // .timeout(10000)
    // No es necesario hacer res.json() ya que HttpClient lo realiza por defecto
    // .map((res) => {
    //  return res.json();
    // })
    // .catch(this.validateError);
  }

  public async logoutSAML() {
    await this.storage.set('user', null);
    await this.setToken(null);
    this.userService.setUser(null);
    this.setState(AuthService.NO_AUTHENTICATED);
  }

  public doLogin() {
    this.loginSAML().subscribe( {
      next: (result: AuthResult) => {
        if (!result) {
          // console.error('ok 4');
          this.lastLoginError = 'Error al autenticar' + JSON.stringify(result);
          alert('Error al autenticar' + JSON.stringify(result));
          this.setState(AuthService.NO_AUTHENTICATED);
        } else if (result.errNo === '1' || result.errNo === '3') {
          // this.externalLogin();
          alert('No soportado');
          // console.error('ok 5');
          this.lastLoginError = 'No soportado';
        } else {
          this.userService.setUser(
            new User(result.nsrId, result.email, result.email, result.name, result.lastName, Object.values(result.roles))
          );
          this.userService.getUser().setId(result.id);
          this.userService.getUser().setDate(result.date);
          this.setState(AuthService.AUTHENTICATED);
        }
      },
      error: (error) => {
        this.lastLoginError = error;
      }
    });
  }

  public doLogout() {
    const subscription = this.logout().subscribe(
      (res) => {
        subscription.unsubscribe();
      },
      (error) => {
        console.log('error when doing logout ' + error);
      }
    );
  }

  public requestResetPassword(body: FormData): Observable<ApiResult> {
    // body.forEach((value, key) => console.log(`${key}:`, value));
    return this.httpClient.post<ApiResult>(environment.apiUrl + '/auth/requestResetPassword', body)
      .pipe(
        tap(res => {
          console.log('auth request reset: ' + JSON.stringify(res));
        })
      );
  }

  public resetPassword(body: FormData): Observable<ApiResult> {

    return this.httpClient.post<ApiResult>(environment.apiUrl + '/auth/resetPassword', body)
      .pipe(
        tap(res => {
          console.log('auth reset: ' + JSON.stringify(res));
        })
      );
  }
  public validateToken(token?: string) {
    let body = '';
    if (token) {
      body = JSON.stringify({token});
    }
    return this.httpClient
        .post(environment.apiUrl + '/auth', body)
        .pipe(timeout(10000), catchError(this.validateError));
    // .timeout(10000)
    // No es necesario hacer res.json() ya que HttpClient lo realiza por defecto
    // .map((res) => {
    //  return res.json();
    // })
    // .catch(this.validateError);
  }
  public validateError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('ValidateToken client error: ', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `ValidateToken backend error: Backend returned code ${error.status}, ` +
        `body was:`, JSON.stringify(error.error)
      );
    }
    // return an observable with a user-facing error message
    return throwError(error.error.result);
  }

  private setState(src: number): void {
    this.state.next(src);
  }
}
